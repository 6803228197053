
@import '~antd/dist/antd.css';



@font-face {
    font-family: 'zkgdh_font';
    src: url('../public/font/站酷高端黑修订151105.ttf');
}




@font-face {
    font-family: 'din_medium_font';
    src: url('../public/font/DIN-Medium.otf');
}


@font-face {
    font-family: 'din_black_italic_font';
    src: url('../public/font/DIN-BlackItalic.otf');
}

.bodyContainer {
    background: #EDEFF3;
    position: absolute;
    width: 100%;
    height: 130%;
}

.menu-container {
    background: #fff;
    position: absolute;
    height: 100%;
}

.table-container {
    position: absolute;
    top: 80px;
    left: 280px;
    width: 75%;
}