.login-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(top,white, lightblue);
}

.login-form {
    margin: 160px auto;
    background-color: #fff;
    width: 400px;
    height: 250px;
    padding-top: 30px;
    border-radius: 5px;
}

.login-title {
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
    
}

.login-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 40px;
    line-height: 40px;
}

.login-item-key{
    width: 30%;
}
 

.login-input {
    height: 40px;
}


.login-btn {
    margin-top: 20px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #3232ef;
    color: #fff;
    margin-left: 38px;
    margin-right: 38px;
    border-radius: 5px;
    cursor: pointer;
}

