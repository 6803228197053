

.user-detail-header {
    padding-left: 20px;
    padding-top: 20px;
    background: #fff;
}

.user-detail-info-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-size: 14px;
}

.user-detail-info-item {
    width: 30%;
    height: 40px;
    line-height: 40px;
}

.user-detail-report {
    margin-top: 20px;
    background: #fff;
    padding-left: 20px;
    padding-top: 20px;
}


.user-detail-report-peroid-list {
    display: flex;
}

.user-detail-report-peroid-item {
    width: 80px;
    border: 1px solid gray;
    text-align: center;
    height: 30px;
    line-height: 30px;
    border-collapse:collapse;
    cursor: pointer;
}

.user-detail-report-period-item-right {
    margin-right: -1px;
}

.user-detail-report-period-item-selected {
    border: 1px solid #1890ff;
    color: #1890ff;
    z-index: 2;
}

#user-detail-report-chart {
    width: 100%;
    height: 400px;
}