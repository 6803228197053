.dashboard-container {
    margin: 0 auto;
    background: url(../../../public/assets/background.jpeg) no-repeat;
    background-size: contain;
    padding-top: 9px;
    width: 1394px;
    height: 784px;
    z-index: -100;
}

.logo {
    background: url(../../../public/assets/logo.png) no-repeat;
    background-position: center top;
    background-size: contain;
    width: 100%;
    height: 30px;
}

.backToChina {
    color: red;
    width: 200px;
    height: 40px;
    margin: 0 auto;
}


.title {
    color: #2FC8FF;
    margin: 10px auto;
    text-align: center;
    font-size: 24px;
    font-family:'zkgdh_font';
}

.count {
    display: flex;
    position: absolute;
    top: 65px;
    left: 70px;
    color: #00FFDF;
    font-size: 36px;
}

.itemCount {
    display: flex;
    align-items: baseline;
    font-family: 'din_black_italic_font';
    width: 56px;
    text-align: right;
    flex-direction: row-reverse;
}

.countUnit {
    font-size: 15px;
}


.countRight {
    margin-left: 54px;
}


.datetime {
    position: absolute;
    color: #2DCCFF;
    left: 340px;
    top: 44px;
    font-family: 'din_medium_font';
    font-size: 15px;
}


.realtimeOilPrice {
    display: flex;
    position: absolute;
    color: #00FFDF;
    left: 340px;
    top: 78px;
    font-size: 12px;
    align-items: baseline;
}

.rmb {
    font-size: 22px;
    padding-right: 5px;
    font-family: "din_black_italic_font";
}

.countColor {
    color: #00FFDF;
}


.oilFieldOutputCount {
    position: absolute;
    left: 931px;
    top: 40px;
    color: #fff;
    font-size: 10px;
    line-height: 15px;
    transform: scale(0.83);  /*浏览器最小只支持12px，所以用scale实现*/
}


.employeeCount {
    position: absolute;
    left: 931px;
    top: 90px;
    color: #fff;
    font-size: 10px;
    line-height: 15px;
    transform: scale(0.83)
}


.oilWellOutputCount {
    position: absolute;
    left: 1078px;
    top: 29px;
    color: #fff;
    font-size: 10px;
    line-height: 15px;
    transform: scale(0.83)
}


.recordCount {
    position: absolute;
    left: 1078px;
    top: 87px;
    color: #fff;
    font-size: 10px;
    line-height: 15px;
    transform: scale(0.83)
}

.collectRecordCount {
    position: absolute;
    left: 1229px;
    top: 32px;
    color: #fff;
    font-size: 10px;
    line-height: 15px;
    transform: scale(0.83)
}



.storageRecordCount {
    position: absolute;
    left: 1229px;
    top: 83px;
    color: #fff;
    font-size: 10px;
    line-height: 15px;
    transform: scale(0.83)
}



.totalCount {
    position: absolute;
    left: 73px;
    top: 161px;
    color: #fff;
    font-size: 13px;
    line-height: 36px;
    height: 36px;
}

.totalCountItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.totalCountNumber {
    font-size: 22px;
    color: #2DCCFF;
    font-family: "din_black_italic_font";
}


.totalCountItemText {
    display: flex;
    align-items: baseline;
}


.unit {
    font-size: 13px;
    color: #2DCCFF;
}


.todayOilPieChart {
    position: absolute;
    top: 360px;
    left: 32px;
}


.todayOilBarChart {
    position: absolute;
    top: 552px;
    left: 32px;
}


.historyLineChart {
    position: absolute;
    top: 520px;
    left: 300px;

}


.address {
    position: absolute;
    top: 504px;
    left: 342px;
    color: #1FCEFF;
    font-size: 13px;
}


.dealtOil {
    position: absolute;
    top: 600px;
    left: 980px;
    color: #02F6A4;
    display: flex;
    align-items: baseline;
    font-family: 'din_black_italic_font';
}

.dealtOilValue {
    font-size: 26px;
}

.dealtOilUnit {
    font-size: 20px;
}




.dealtWater {
    position: absolute;
    top: 686px;
    left: 980px;
    color: #20C4FF;
    display: flex;
    align-items: baseline;
    font-family: 'din_black_italic_font';
}

.dealtWaterValue {
    font-size: 26px;
}

.dealtWaterUnit {
    font-size: 20px;
}



.valuableOilField {
    position: absolute;
    top: 200px;
    left: 1100px;
    width: 256px;
    height: 367px;
    border-radius: 4px;
    text-align: center;
    border: 1px solid #80C4DB;

}


.valuableRow {
    display: flex;
}


.valuableColumn {
    width: 25%;
    font-size: 12px;
    border-bottom: 1px solid #80C4DB;
    color: #A0EBFF;
    padding: 3px 2px;
}

.valuableSecondColumn {
    border-left: 1px solid #80C4DB;
    border-right: 1px solid #80C4DB;
}


.valuableThirdColumn {
    border-right: 1px solid #80C4DB;
}

.valuableFirstRow .valuableColumn{
    font-weight: 500;
    color: #64D8FF;
}


.selectedRow {
    border: 1px solid #00FDDC;
    box-sizing: content-box;
}

.selectedRow .valuableColumn {
    color: #00FDDC;
}


.selectedRow::before{
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        margin-left: -15px;
        margin-top: 5px;
        border-width: 5px 5px 5px 10px;
        border-color: transparent transparent transparent #00FDDC;
}


.selectedOilField {
    position: absolute;
    top: 590px;
    left: 1100px;
    color: #1DCCFF;
    font-size: 12px;
}

.firstRow {
    display: flex;
    align-items: center;
    width: 260px;
}

.leftIcon {
    background: url(../../../public/assets/left.png) no-repeat;
    width: 16px;
    height: 10px;
    background-size: contain;
}


.line {
    flex: 1;
    height: 1px;
    width: 10px;
    background: #1DCCFF;
}


.rightIcon {
    background: url(../../../public/assets/right.png) no-repeat;
    width: 16px;
    height: 10px;
    background-size: contain;
}


.circleList {
    position: absolute;
    top: 620px;
    left: 1100px;
    display: flex;
    width: 260px;
    justify-content: space-around;
    font-family: 'din_medium_font';
}


.circleOil {
    width: 100px;
    height: 100px;
    background: url(../../../public/assets/oil-bg.png) no-repeat;
    background-size: contain;
    color: #02F6A4;
}


.circleWater {
    width: 100px;
    height: 100px;
    background: url(../../../public/assets/water-bg.png) no-repeat;
    background-size: contain;
    color: #1DCCFF;

}

.circleItem {
    display: flex;
    height:30px;
    margin-top: 26px;
    font-size: 16px;
    text-align: center;
    align-items: baseline;
}

.circleNumber {
    margin: 0 auto;
}

.circleUnit {
    font-size: 14px;
}

.circleText {
    position: relative;
    left: 30px;
    font-size: 12px;
}


.mapChart {
    position: absolute;
    top: 100px;
    left: 364px;
    /* width: 666px;
    height: 446px; */
     z-index: 10000;
}

.custom-tooltip-box{
    padding: 0 !important;
    border: none !important;
    background-color: transparent !important;
    /* // 给子盒子自定义样式 */

  }
.custom-tooltip-box  .custom-tooltip-style {
    /* width: 92px;
    height: 171px; */
    padding: 10px 20px;
    background-image: url(../../../public/assets/hover_earch_bg.png);
    background-size: cover;
    background-size: 100% 100%;
    color: #fff;
}