.admin-header-container {
    width: 100%;
    height: 48px;
    background: #01101F;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    justify-content: space-between;
}

.admin-header-left {
    display: flex;
    align-items: center;
}

.admin-header-logo {
    background: url('../../../../public/assets/logo.png') no-repeat;
    width: 40px;
    height: 30px;
    background-size: contain;
    background-position:center
}

.admin-header-title {
    color: #fff;
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
}


.admin-header-right {
    display: flex;
    align-items: center;
    padding: 10px;
    height: 48px;
}

.admin-header-right-show-dropdown {
    background: #262A3C;
    cursor: pointer;
}


.admin-header-avatar {
    background: url('../../../../public/assets/avatar.jpeg') no-repeat;
    width: 24px;
    height: 24px;
    background-size: contain;
    border-radius: 15px;
    margin-right: 10px;
}


.admin-header-name {
    color: #fff;
    font-size: 14px;
}

.admin-header-dropdown-container {
    position: absolute;
    color: #646566;
    right: 30px;
    top: 48px;
    padding: 0 10px;
    width: 120px;
    z-index: 10;
}

.admin-header-dropdown-item {
    height: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
}


.admin-header-dropwond-item-avatar-icon {
    background: url('../../../../public/assets/myavater.png') no-repeat;
    width: 16px;
    height: 16px;
    background-size: contain;
}

.admin-header-dropwond-item-quit-icon {
    background: url('../../../../public/assets/quit.png') no-repeat;
    width: 16px;
    height: 16px;
    background-size: contain;
}

.admin-header-spilt-line {
    background-color: #EDEFF3;
    height: 1px;
}

.admin-header-dropwond-item-text {
    margin-left: 3px;
}