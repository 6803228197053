

.device-detail-header {
    padding-left: 20px;
    padding-top: 20px;
    background: #fff;
}

.device-detail-info-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-size: 14px;
}

.device-detail-info-item {
    width: 30%;
    height: 40px;
    line-height: 40px;
}


.device-detail-history-log {
    margin-top: 20px;
    background: #fff;
}

.device-detail-history-log-title {
    padding: 20px;
}
